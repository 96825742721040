import { Duvod_text } from './Components'
import { useTranslation } from 'react-i18next';
import { language } from './Settings'


async function saveRequest(props) {
  const rawResponse = await fetch('https://vratky.bola.cz/vratky-backend/save.php', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({form: props})
  });

  await rawResponse.text()
    .then((dataStr) => {
      try {
        let res = JSON.parse(dataStr);
        if (res.status === 'ok') {
          props.setStatus(res.status)
          props.setStitekInfo(res.stitek_info)
          props.link('finished')
        }
      } catch (err) {
        console.log(err)
        window.alert('Chyba. \n\n' + err + '\n\n' + dataStr)
      }
    }
  );
}

function translate_action(action, language) {
  if (language === 'en') {
    let newAction = action.replace('vratka', 'return')
    newAction = newAction.replace('reklamace', 'claim')
    return newAction
  }
  return action
}


export function Summary(props) {
  const { t } = useTranslation();

  const sendData = () => {
    console.log('odesilam')
    saveRequest(props)
  }

  return (
  
      <div className="row">

        <div className="col-12">

          {props.typVraceni === 'vratka' && <h2>{t('Souhrn vratky')}</h2>}
          {props.typVraceni === 'reklamace' && <h2>{t('Souhrn reklamace')}</h2>}

          <div className="row mb-3">
            <div className="col-11">
              <label><strong>{t('Co provádíte:')}</strong></label><br />
              {translate_action(props.typVraceni, language)[0].toUpperCase() + translate_action(props.typVraceni, language).substring(1)}<br /><br />

              <label><strong>{t('Číslo objednávky:')}</strong></label><br />
              {props.data.objednavka.cislo_objednavky}<br /><br />

              <label><strong>{t('E-mail:')}</strong></label><br />
              {props.userInput.user_email}<br /><br />

              <label><strong>{t('Produkty, které vracíte či reklamujete:')}</strong></label><br />
             {props.userInput.vracene_polozky && props.userInput.vracene_polozky.map(item => {
            return (
              <span key={item.product_code}>
               {item.product_code} {item.product_name} ({item.amount} ks)<br />
              </span>
            );
          })}
              <br />
              <label><strong>{t('Důvod vrácení či reklamace:')}</strong></label><br />
             {Duvod_text(props.userInput.duvod_vraceni)}<br /><br />
              <label><strong>{t('Rozepište důvod vrácení či reklamace:')}</strong></label><br />
             {props.userInput.duvod_vraceni_detail}<br /><br />

             {props.data.objednavka.shop_country_code === 'cz' &&
              <span>
                 <label><strong>{t('Číslo účtu pro případné vrácení peněz:')}</strong></label><br />
                 {props.userInput.cislo_uctu_user}<br /><br />
              </span>
             }

            {props.data.objednavka.shop_country_code !== 'cz' &&
              <span>
                 <label><strong>{t('IBAN číslo účtu pro případné vrácení peněz:')}</strong></label><br />
                 {props.userInput.iban_user}<br /><br />
              </span>
            }

              <label><strong>{t('Kontaktní jméno:')}</strong></label><br />
              {props.userInput.contact_name}<br /><br />
              <label><strong>{t('Kontaktní telefon:')}</strong></label><br />
              {props.userInput.contact_phone}<br /><br />
              <label><strong>{t('Kontaktní email:')}</strong></label><br />
              {props.userInput.contact_email}<br /><br />

            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <button className="btn btn-secondary me-3" onClick={() => props.link('form')}>{t('Zpět')}</button>
              <button className="btn btn-primary" onClick={() => sendData()}>{t('Odeslat')}</button>
            </div>
          </div>

        </div>
      </div>

    )
}