import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

async function checkData(props, setError) {
  var result = await get_data(props)
  if (!result || result.error_message) {
    await setError(result.error_message)
  } else if (result.faktury.length > 1) { //vice faktur na jednu objednavku
    await setError('multiple_invoices')
    await props.setData(result)
  } else {
    await setError(result.error_message)
    await props.setData(result)
    props.setContactData({
      'contact_phone': result.objednavka.obj_telefon || result.objednavka.par_tel || result.objednavka.par_mobil,
      'contact_name': result.objednavka.nazev_odb,
      'contact_email': props.userInput.user_email
    }) 

    props.link('product_selector')
  }
}

async function get_data(props) {
    let url = 'https://vratky.bola.cz/vratky-backend/data.php?cislo='+props.userInput.user_cislo_dokladu+'&email='+props.userInput.user_email+'&no_order_email='+props.userInput.no_order_email
    let result = false

    await fetch(url)
    .then(res => res.json())
    .then((out) => {
      result = out
    })
    .catch(err => {
      console.log(err)
    });

    return result
  }

async function get_email(props, setEmails) {
    let url = 'https://vratky.bola.cz/vratky-backend/getEmail.php?cislo='+props.userInput.user_cislo_dokladu
    let result = false

    await fetch(url)
    .then(res => res.json())
    .then((out) => {
      result = out
      setEmails(out)
    })
    .catch(err => {
      console.log(err)
    });

    return result
  }


export function BaseInput(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState(false);

  return (
     <div className="row">

      <div className="col-12">

        <div className="row">
          <div className="col-12">
            <label>{t('Číslo objednávky nebo faktury')}<span className="text-danger">*</span></label>
            <input type="text" className="form-control" name='user_cislo_dokladu' value={props.userInput.user_cislo_dokladu} onChange={props.handleUserInput} onBlur={() => get_email(props, setEmails)} placeholder="..."  />

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <label>{t('Email, pod kterým jste objednávali')}<span className="text-danger">*</span></label>
            <input type="text" className="form-control" disabled={props.userInput.no_order_email} name="user_email" value={props.userInput.user_email} onChange={props.handleUserInput} placeholder={t('jmeno@domena.cz')} />
            {emails &&
              <>
                {/* email z objednavky, pokud ho mame */}
                {emails.obj_email &&
                  <>
                    <span><a onClick={(e) => {e.preventDefault(); props.handleUserInput({target: {name: 'user_email', value: emails.obj_email}})}} href="#1">{emails.obj_email}</a> - email z objednávky</span><br />
                  </>
                }
                {/* email z partnera, pokud ho mame a neni stejnej jako ten z objednavky*/}
                {emails.par_email && emails.obj_email !== emails.par_email &&            
                  <>
                    <span><a onClick={(e) => {e.preventDefault(); props.handleUserInput({target: {name: 'user_email', value: emails.par_email}})}} href="#1">{emails.par_email}</a> - email z IS</span><br />
                  </>
                }
                {/* login email shopu, pokud ho mame a neni stejnej jako ten z objednavky nebo partnera */}
                {emails.par_email_login && emails.par_email_login !== emails.obj_email && emails.par_email_login !== emails.par_email &&
                  <>
                    <span><a onClick={(e) => {e.preventDefault(); props.handleUserInput({target: {name: 'user_email', value: emails.par_email_login}})}} href="#1">{emails.par_email_login}</a> - email ze shopu</span><br />
                  </>
                }
              </>
            }


            <div className="form-check mt-3">
              <input className="form-check-input" type="checkbox" value="" name="no_order_email" checked={props.userInput.no_order_email} onChange={props.handleUserInput} />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {t('Bez emailu (koupeno osobně na prodejně)')}
              </label>
            </div>
          </div>
        </div>

        {
          error &&
          <div className="alert alert-danger mt-3" role="alert">
            {error === 'wrong_email' && <>{t('Nesprávný email')}</>}
            {error === 'order_not_found' && <>{t('Objednávka nenalezena')}</>}
            {error === 'no_order_number' && <>{t('Není zadáno číslo objednávky')}</>}
            {error === 'no_email' && <>{t('Není zadán email')}</>}
            {error === 'wrong_doc_number' && <>{t('Nesprávně zadané číslo dokladu')}</>}
            {error === 'ordered_with_email' && <>{t('Objednávka byla s emailem, prosíme zadejte jej')}</>}
            {error === 'multiple_invoices' && 
              <>
                {t('Objednávka byla rozdělena na více faktur. Prosím zadejte či vyberte jednu z nich:')}
                <br />
                {props.data && props.data.faktury && props.data.faktury.map(item => {
                  return (
                    <span key={item.cislo_faktury}><a onClick={(e) => {
                      e.preventDefault()
                      props.handleUserInput({'target' :{'name': 'user_cislo_dokladu', 'value': item.cislo_faktury}})}
                      } href="#f">{item.cislo_faktury}</a><br /></span>
                  )
                  })
                }
              </>
            }

            
          </div>
        }

        <div className="row mt-3">
          <div className="col-12">
            <button className="btn btn-secondary me-3" onClick={() => props.link('init')}>{t('Zpět')}</button>
            <button className="btn btn-primary" onClick={() => checkData(props, setError)}>{t('Pokračovat')}</button>
          </div>
        </div>

      </div>
    </div>
  )
}

