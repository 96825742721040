import React, { useState } from 'react';
import structuredClone from '@ungap/structured-clone';
import { useTranslation } from 'react-i18next';
import { isInt } from './Components'


function addOrRemoveProduct(item, props) {
  let product = structuredClone(item)
  let returns = props.userInput.vracene_polozky
  if (returns.some(e => e.product_code === item.product_code)) { //remove existing
    returns = returns.filter(function( obj ) {
      return obj.product_code !== item.product_code;
    })
  } else { //add new

    if (item.amount > 1) { //pokud bylo objednano vic ks, chceme aby zakaznik explicitne zadal kolik jich vraci, protoze jinak delaj chyby
      product.amount = ''
    } else {
      product.amount = 1
    }
    
    returns.push(product)
  }
  
  returns = {
    target: {
      value: returns,
      name: 'vracene_polozky'
    }
  }
  props.handleUserInput(returns)
}

export function ProductSelector(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const forward = () => {
    if (props.userInput.vracene_polozky.length === 0) {
      setError('no_returned_items')
    } else if (!returnedItemsHaveAmounts()) {
      setError('missing_amounts')
    } else {
      props.link('form')
    }
  }

  const returnedItemsHaveAmounts = () => {
    for (const element of props.userInput.vracene_polozky) {
      if (!element.amount || !isInt(element.amount)) {
        return false
      }
    }
    return true
  }

  const findReturnedProduct = (product_code) => {
    let product = props.userInput.vracene_polozky.find(function( obj ) {
      return obj.product_code === product_code;
    })
    return product
  }

  const changeAmount = (event, item) => {
    let newAmount = event.target.value
    if (newAmount > item.amount) newAmount = item.amount
    props.setUserInput(prevState => ({
      ...props.userInput,
      vracene_polozky: prevState.vracene_polozky.map(
        el => el.product_code === item.product_code ? { ...el, amount: newAmount }: el
      )
    }))
  }

  return (  
      <div className="row">

        <div className="col-12">

          {props.typVraceni === 'vratka' && <h2>{t('Prosíme vyberte vracené produkty')}</h2>}
          {props.typVraceni === 'reklamace' && <h2>{t('Prosíme vyberte reklamované produkty')}</h2>}

          {props.products && props.products.map(item => {
            let button_class = 'btn btn-primary float-end'
            if (props.userInput.vracene_polozky.some(e => e.product_code === item.product_code)) {
              button_class = 'btn btn-secondary float-end'
            }
            return (
              <div key={item.product_code} className="card mt-3">
                <div className="card-body">
                <button onClick={() => addOrRemoveProduct(item, props)} className={button_class}>
                 {props.userInput.vracene_polozky.some(e => e.product_code === item.product_code) &&
                  <>
                    {props.typVraceni === 'vratka' && t('Zrušit')}
                    {props.typVraceni === 'reklamace' && t('Zrušit')}
                  </>
                 }
                 {!props.userInput.vracene_polozky.some(e => e.product_code === item.product_code) &&
                  <>
                    {props.typVraceni === 'vratka' && t('Označit k vrácení')}
                    {props.typVraceni === 'reklamace' && t('Označit k reklamaci')}
                  </>
                 }
                </button>
                  <h5 className="card-title">{ item.product_name }</h5>
                  <h6 className="card-subtitle mb-2 text-muted">{ item.product_code }</h6>
                  <h6 className="card-subtitle mb-2 text-muted">{t('Koupeno ks:')} { item.amount }</h6>
                    {props.userInput.vracene_polozky.some(e => e.product_code === item.product_code) &&
                      <>
                      <div className="alert alert-primary" role="alert">
                        {props.typVraceni === 'vratka' && t('Zahrnuto do vratky')}
                        {props.typVraceni === 'reklamace' && t('Zahrnuto do reklamace')}
                      </div>
                      <label>{t('Počet ks:')}</label>
                        <input style={{maxWidth: '150px'}} type="number" name="amount" min="1" max={item.amount} value={findReturnedProduct(item.product_code).amount} onChange={(event) => changeAmount(event, item)} className="form-control" />
                    </>
                    }
                </div>
              </div>
            );
          })}
          {
            error && error === 'no_returned_items' && props.userInput.vracene_polozky.length === 0 &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Vyberte alespoň jeden produkt')}
            </div>
          }
          {
            error && error === 'missing_amounts' && 
            <div className="alert alert-danger mt-3" role="alert">
              {t('Zadejte množství produktu')}
            </div>
          }
          <button className="btn btn-secondary me-3 mt-3" onClick={() => props.link('baseInput')}>{t('Zpět')}</button>
          <button onClick={() => {forward()}} className="btn btn-primary mt-3">{t('Pokračovat')}</button>
        </div>

      </div>

    )
}