import React, { useState } from 'react';
import './App.css';
import { ReklamaceBox, VratkaBox } from './Components'
import { Reklamace } from './Reklamace'
import { Vratka } from './Vratka'
import { BaseInput } from './BaseInput'
import { ProductSelector } from './ProductSelector'
import { Summary } from './Summary'
import { Finished } from './Finished'
import { KontaktniUdaje } from './KontaktniUdaje'
import { useTranslation } from 'react-i18next';


function App() {
  const { t } = useTranslation();
  const [typVraceni, setTypVraceni] = useState('init');
  const [page, setPage] = useState('init');
  const [data, setData] = useState(false);
  const [status, setStatus] = useState(false);
  const [stitekInfo, setStitekInfo] = useState('');
  const [userInput, setUserInput] = useState({
    duvod_vraceni: '14_dni',
    duvod_vraceni_detail: '',
    cislo_uctu_user: '',
    iban_user: '',
    vracene_polozky: [],
    user_cislo_dokladu: '',
    user_email: '',
    no_order_email: false,
    no_account_number: false,
    contact_phone: '',
    contact_name: '',
    contact_email: ''
  });

  const handleUserInput = async (event) => {
    await setUserInput({
      ...userInput,
      [event.target.name]: event.target.value || event.target.checked || '',
      ...(event.target.name == 'no_account_number' && event.target.checked) && {cislo_uctu_user: ''},
      ...(event.target.name == 'no_account_number' && event.target.checked) && {iban_user: ''},
    })
  }

  const setContactData = async (event) => {
    await setUserInput({
      ...userInput,
      contact_phone: event.contact_phone || '',
      contact_name: event.contact_name || '',
      contact_email: event.contact_email || '',
    })
  }

  return (
    <div>

      {/* pick reklamace or vratka */}
      {page === 'init' &&
        <div className="app">
          <h1>{t('Vratky a reklamace')}</h1> 
          <div className="row">
            <div className="col-5">
              <ReklamaceBox setTypVraceni={setTypVraceni} handleUserInput={handleUserInput} link={setPage} />
            </div>
            <div className="col-5">
              <VratkaBox setTypVraceni={setTypVraceni} handleUserInput={handleUserInput} link={setPage} />
            </div>      
          </div>
        </div>
      }

      {/* base Input form */}
      { page === 'baseInput' &&
        <div className="app">
        {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
        {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <BaseInput 
            page={page} 
            link={setPage} 
            handleUserInput={handleUserInput}
            userInput={userInput}
            setData={setData}
            data={data}
            setContactData={setContactData}
          />
        </div>
      }

      {/* reklamace product selector */}
      { (page === 'product_selector') &&
        <div className="app">
          {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
          {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <ProductSelector 
            userInput={userInput} 
            products={data.polozky} 
            link={setPage} 
            handleUserInput={handleUserInput}
            setUserInput={setUserInput}
            typVraceni={typVraceni}
          />
        </div>
      }

      {/* reklamace form */}
      { page === 'form' && typVraceni === 'reklamace' &&
        <div className="app">
        {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
        {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <Reklamace 
            link={setPage} 
            userInput={userInput} 
            setUserInput={setUserInput} 
            handleUserInput={handleUserInput} 
            data={data} 
          />
        </div>
      }

      {/* vratka form */}
      { page === 'form' && typVraceni === 'vratka' &&
        <div className="app">
          {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
          {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <Vratka 
            link={setPage} 
            userInput={userInput} 
            setUserInput={setUserInput} 
            handleUserInput={handleUserInput} 
            data={data} 
          />
        </div>
      }

      {/* reklamace product selector */}
      { (page === 'summary') &&
        <div className="app">
          {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
          {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <Summary 
            userInput={userInput} 
            status={status}
            data={data} 
            link={setPage} 
            typVraceni={typVraceni}
            setStatus={setStatus}
            setStitekInfo={setStitekInfo}
          />
        </div>
      }

      {/* reklamace product selector */}
      { (page === 'finished') &&
        <div className="app">
          {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
          {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <Finished 
            userInput={userInput} 
            data={data} 
            link={setPage} 
            typVraceni={typVraceni}
            stitekInfo={stitekInfo}
          />
        </div>
      }

            {/* reklamace product selector */}
      { (page === 'contact') &&
        <div className="app">
          {typVraceni === 'vratka' && <h1>{t('Vratka')}</h1>}
          {typVraceni === 'reklamace' && <h1>{t('Reklamace')}</h1>}
          <KontaktniUdaje 
            userInput={userInput} 
            data={data} 
            link={setPage} 
            typVraceni={typVraceni}
            stitekInfo={stitekInfo}
            handleUserInput={handleUserInput}
          />
        </div>
      }

    </div>

    );
  }

export default App;