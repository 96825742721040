import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { language } from './Settings'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Vratky a reklamace': 'Returns and claims',
      'Reklamace': 'Claims',
      'Reklamace zboží.' : 'Claim your products.',
      'Pokračovat': 'Continue',
      'Vratka': 'Return',
      'Vrácení zboží.' : 'Return your products',
      'Číslo objednávky nebo faktury' : 'Order or invoice number',
      'Email, pod kterým jste objednávali' : 'The email you ordered with',
      'Bez emailu (koupeno osobně na prodejně)' : 'No email (bought in person at the store)',
      'jmeno@domena.cz' : 'name@domain.com',
      'Nesprávný email' : 'Wrong email',
      'Objednávka nenalezena': 'Order not found',
      'Není zadáno číslo objednávky': 'Order number not entered',
      'Není zadán email': 'Email not entered',
      'Nesprávně zadané číslo dokladu': 'Incorrect document number',
      'Objednávka byla s emailem, prosíme zadejte jej' : 'Order was made with an email, please enter it',
      'Objednávka byla rozdělena na více faktur. Prosím zadejte či vyberte jednu z nich:' : 'The order has been split into multiple invoices. Please enter or select one of them:',
      'Zpět': 'Back',
      //productSelector//
      'Prosíme vyberte reklamované produkty' : 'Please select products you wish to claim',
      'Prosíme vyberte vracené produkty': 'Please select products you wish to return',
      'Nevracet': 'Don\'t return',
      'Vrátit': 'Return',
      'Koupeno ks:' : 'Pieces bought:',
      'Zahrnuto do reklamace / vratky' : 'Included in the claim / return',
      'Zahrnuto do vratky': 'Included in the return',
      'Zahrnuto do reklamace': 'Included in the claim',
      'Počet ks:' : 'Number of pieces:',
      'Vyberte alespoň jeden produkt' : 'Please select at least one product',
      'Zadejte množství produktu': 'Please enter the quantity of the product',
      //productSelector end//
      //duvody vraceni//
      'Bez důvodu do 14ti dní (pouze koncový zákazník)' : 'Without specifying a reason within 14 days (end customer only)' ,
      'Poškozeno při přepravě' : 'Damaged during transport',
      'Chyba na straně Bola spol s.r.o.' : 'An error on the part of Bola spol s.r.o.',
      'Chyba na straně zákazníka': 'Error on the customer\'s side',
      'Jiný': 'Other',
      //end//
      //Finished.js//
      'Vratka byla vytvořena.': 'The return was created.',
      'Reklamace byla vytvořena.': 'The claim has been created.',
      'Prosím zašlete či doručte balík na adresu:': 'Please send or deliver the package to:',
      'Vepište na štítek/balík:' : 'Write on the label / package:',
      'Vytisknout štítek na balík' : 'Print package label',
      //end//
      //Reklamace.js//
      'Důvod reklamace': 'The reason for the claim',
      'Rozepište důvod reklamace': 'Describe the reason for the claim',
      'Číslo účtu pro případné vrácení peněz': 'Account number for any refunds',
      'IBAN číslo účtu pro případné vrácení peněz': 'IBAN account number for any refunds',
      'Prosím popište důvod reklamace zboží.': 'Please describe the reason for the claim.',
      'Prosím zadejte číslo účtu.': 'Please enter an account number.',
      'Prosím zadejte IBAN číslo účtu.': 'Please enter an IBAN account number.',
      //end//
      //Summary.js//
      'Souhrn vratky': 'Return summary',
      'Souhrn reklamace': 'Claim summary',
      'Co provádíte:': 'Your action',
      'Číslo objednávky:': 'Order number',
      'E-mail:': 'E-mail:',
      'Produkty, které vracíte či reklamujete:': 'Products to claim or return:',
      'Důvod vrácení či reklamace:': 'The reason for the claim or return:',
      'Rozepište důvod vrácení či reklamace:': 'Describe the reason for the return or claim:',
      'IBAN číslo účtu pro případné vrácení peněz:': 'IBAN account number for any refunds',
      'Odeslat': 'Submit',
      'Číslo účtu pro případné vrácení peněz:': 'Bank account number for any refunds:',
      'Kontaktní jméno:' : 'Contact name',
      'Kontaktní telefon:': 'Contact phone',
      'Kontaktní email:': 'Contact email',
      //end//
      //Vratka.js//
      'Důvod vratky': 'Reason for the return',
      'Důvod vrácení': 'Reason for the return',
      'Prosíme rozepište důvod vrácení': 'Please specify the reason for the return',
      'Prosím popište důvod vrácení zboží.': 'Please specify the reason for returning your items.',
      'Bez čísla účtu' : 'No account number',
      'Nespravný IBAN. Zadejte jej prosím ve formátu XX1111111111 a zkontrolujte na překlepy.': 'Incorrect IBAN. Please enter it in the format XX1111111111 and check for typos.',
      //KontatkniUdaje
      'Jméno': 'Name',
      'Telefon': 'Phone',
      'Kontaktní údaje': 'Contact details',
      'Prosím vyplňte kontaktní jméno.' : 'Please enter contact name.',
      'Prosím vyplňte kontaktní telefon.' : 'Please enter contact phone.',
      'Prosím vyplňte kontaktní email.': 'Please enter contact email.',
      'Označit k reklamaci': 'Select for claiming',
      'Označit k vrácení': 'Select for returning',
      'Zrušit': 'Cancel',
    }
  },
  sk: {
    translation: {
      'Vratky a reklamace': 'Vratky a reklamácia',
      'Reklamace': 'Reklamácia',
      'Reklamace zboží.' : 'Reklamácia tovaru.',
      'Pokračovat': 'Pokračovať',
      'Vratka': 'Vratka',
      'Vrácení zboží.' : 'Vrátenie tovaru',
      'Číslo objednávky nebo faktury' : 'Číslo objednávky alebo faktúry',
      'Email, pod kterým jste objednávali' : 'Email, pod ktorým ste objednávali',
      'Bez emailu (koupeno osobně na prodejně)' : 'Bez emailu (kúpené osobne na predajni)',
      'jmeno@domena.cz' : 'meno@domena.sk',
      'Nesprávný email' : 'Nesprávný email',
      'Objednávka nenalezena': 'Objednávka nenájdená',
      'Není zadáno číslo objednávky': 'Nie je zadané číslo objednávky',
      'Není zadán email': 'Nie je zadaný email',
      'Nesprávně zadané číslo dokladu': 'Nesprávne zadané číslo dokladu',
      'Objednávka byla s emailem, prosíme zadejte jej' : 'Objednávka bola s emailom, prosíme zadajte ho',
      'Objednávka byla rozdělena na více faktur. Prosím zadejte či vyberte jednu z nich:' : 'Objednávka bola rozdelená na viac faktúr. Prosím zadajte alebo vyberte jednu z nich:',
      'Zpět': 'Späť',
      //productSelector//
      'Prosíme vyberte reklamované produkty' : 'Prosíme vyberte reklamované produkty',
      'Prosíme vyberte vracené produkty': 'Prosíme vyberte vracané produkty',
      'Nevracet': 'Nevracať',
      'Vrátit': 'Vrátiť',
      'Koupeno ks:' : 'Kúpené ks:',
      'Zahrnuto do reklamace / vratky' : 'Zahrnuté do reklamácie / vratky',
      'Zahrnuto do vratky': 'Zahrnuté do vratky',
      'Zahrnuto do reklamace': 'Zahrnuté do reklamácie',
      'Počet ks:' : 'Počet ks:',
      'Vyberte alespoň jeden produkt' : 'Vyberte aspoň jeden produkt',
      'Zadejte množství produktu': 'Zadajte množstvo produktu',
      //productSelector end//
      //duvody vraceni//
      'Bez důvodu do 14ti dní (pouze koncový zákazník)' : 'Bez dôvodu do 14tich dní (len koncový zákazník)' ,
      'Poškozeno při přepravě' : 'Poškodené pri preprave',
      'Chyba na straně Bola spol s.r.o.' : 'Chyba na strane Bola spol s.r.o.',
      'Chyba na straně zákazníka': 'Chyba na strane zákazníka',
      'Jiný': 'Iný',
      //end//
      //Finished.js//
      'Vratka byla vytvořena.': 'Vratka bola vytvorená.',
      'Reklamace byla vytvořena.': 'Reklamácia bola vytvorená.',
      'Prosím zašlete či doručte balík na adresu:': 'Prosím zašlite či doručte balík na adresu:',
      'Vepište na štítek/balík:' : 'Vpíšte na štítok/balík:',
      'Vytisknout štítek na balík' : 'Vytlačiť štítok na balík',
      //end//
      //Reklamace.js//
      'Důvod reklamace': 'Dôvod reklamácie',
      'Rozepište důvod reklamace': 'Rozpíšte dôvod reklamácie',
      'Číslo účtu pro případné vrácení peněz': 'Číslo účtu pre prípadné vrátenie peňazí',
      'IBAN číslo účtu pro případné vrácení peněz': 'IBAN číslo účtu pre prípadné vrátenie peňazí',
      'Prosím popište důvod reklamace zboží.': 'Prosím popíšte dôvod reklamácie tovaru.',
      'Prosím zadejte číslo účtu.': 'Prosím zadajte číslo účtu.',
      'Prosím zadejte IBAN číslo účtu.': 'Prosím zadajte IBAN číslo účtu.',
      //end//
      //Summary.js//
      'Souhrn vratky': 'Súhrn vratky',
      'Souhrn reklamace': 'Súhrn reklamácie',
      'Co provádíte:': 'Čo robíte:',
      'Číslo objednávky:': 'Číslo objednávky:',
      'E-mail:': 'E-mail:',
      'Produkty, které vracíte či reklamujete:': 'Produkty, ktoré vraciate či reklamujete:',
      'Důvod vrácení či reklamace:': 'Dôvod vrátenia či reklamácie:',
      'Rozepište důvod vrácení či reklamace:': 'Rozpíšte dôvod vrátenia či reklamácie:',
      'IBAN číslo účtu pro případné vrácení peněz:': 'IBAN číslo účtu pre prípadné vrátenie peňazí:',
      'Odeslat': 'Odoslať',
      'Číslo účtu pro případné vrácení peněz:': 'Číslo účtu pre prípadné vrátenie peňazí:',
      'Kontaktní jméno:' : 'Kontaktné meno:',
      'Kontaktní telefon:': 'Kontaktný telefón:',
      'Kontaktní email:': 'Kontaktný email:',
      //end//
      //Vratka.js//
      'Důvod vratky': 'Dôvod vratky',
      'Důvod vrácení': 'Dôvod vrátenia',
      'Prosíme rozepište důvod vrácení': 'Prosíme rozpíšte dôvod vrátenia',
      'Prosím popište důvod vrácení zboží.': 'Prosím popíšte dôvod vrátenia tovaru.',
      'Bez čísla účtu' : 'Bez čísla účtu',
      'Nespravný IBAN. Zadejte jej prosím ve formátu XX1111111111 a zkontrolujte na překlepy.': 'Nespravný IBAN. Zadajte ho prosím vo formáte XX1111111111 a skontrolujte na preklepy.',

      //KontatkniUdaje
      'Jméno': 'Meno',
      'Telefon': 'Telefón',
      'Kontaktní údaje': 'Kontaktné údaje',
      'Prosím vyplňte kontaktní jméno.' : 'Prosím vyplňte kontaktné meno.',
      'Prosím vyplňte kontaktní telefon.' : 'Prosím vyplňte kontaktný telefón.',
      'Prosím vyplňte kontaktní email.': 'Prosím vyplňte kontaktný email.',
      'Označit k reklamaci': 'Označiť na reklamáciu',
      'Označit k vrácení': 'Označiť na vrátenie',
      'Zrušit': 'Zrušiť',
    }
  },
  fr: {
    translation: {
      'Vratky a reklamace': 'Retours et réclamations',
      'Reklamace': 'Réclamations',
      'Reklamace zboží.': 'Réclamez vos produits.',
      'Pokračovat': 'Continuer',
      'Vratka': 'Retour',
      'Vrácení zboží.': 'Retournez vos produits',
      'Číslo objednávky nebo faktury': 'Numéro de commande ou de facture',
      'Email, pod kterým jste objednávali': 'L\'email avec lequel vous avez commandé',
      'Bez emailu (koupeno osobně na prodejně)': 'Sans email (acheté en personne en magasin)',
      'jmeno@domena.cz': 'nom@domaine.fr',
      'Nesprávný email': 'Email incorrect',
      'Objednávka nenalezena': 'Commande introuvable',
      'Není zadáno číslo objednávky': 'Numéro de commande non saisi',
      'Není zadán email': 'Email non saisi',
      'Nesprávně zadané číslo dokladu': 'Numéro de document incorrect',
      'Objednávka byla s emailem, prosíme zadejte jej': 'La commande a été passée avec un email, veuillez le saisir',
      'Objednávka byla rozdělena na více faktur. Prosím zadejte či vyberte jednu z nich:': 'La commande a été divisée en plusieurs factures. Veuillez saisir ou sélectionner l\'une d\'elles:',
      'Zpět': 'Retour',
      //productSelector//
      'Prosíme vyberte reklamované produkty': 'Veuillez sélectionner les produits à réclamer',
      'Prosíme vyberte vracené produkty': 'Veuillez sélectionner les produits à retourner',
      'Nevracet': 'Ne pas retourner',
      'Vrátit': 'Retourner',
      'Koupeno ks:': 'Quantité achetée:',
      'Zahrnuto do reklamace / vratky': 'Inclus dans la réclamation / le retour',
      'Zahrnuto do vratky': 'Inclus dans le retour',
      'Zahrnuto do reklamace': 'Inclus dans la réclamation',
      'Počet ks:': 'Nombre de pièces:',
      'Vyberte alespoň jeden produkt': 'Veuillez sélectionner au moins un produit',
      'Zadejte množství produktu': 'Veuillez saisir la quantité du produit',
      //productSelector end//
      //duvody vraceni//
      'Bez důvodu do 14ti dní (pouze koncový zákazník)': 'Sans raison sous 14 jours (client final uniquement)',
      'Poškozeno při přepravě': 'Endommagé pendant le transport',
      'Chyba na straně Bola spol s.r.o.': 'Erreur de la part de Bola spol s.r.o.',
      'Chyba na straně zákazníka': 'Erreur du côté du client',
      'Jiný': 'Autre',
      //end//
      //Finished.js//
      'Vratka byla vytvořena.': 'Le retour a été créé.',
      'Reklamace byla vytvořena.': 'La réclamation a été créée.',
      'Prosím zašlete či doručte balík na adresu:': 'Veuillez envoyer ou livrer le colis à l\'adresse :',
      'Vepište na štítek/balík:': 'Inscrivez sur l\'étiquette / le colis :',
      'Vytisknout štítek na balík': 'Imprimer l\'étiquette du colis',
      //end//
      //Reklamace.js//
      'Důvod reklamace': 'Motif de la réclamation',
      'Rozepište důvod reklamace': 'Décrivez le motif de la réclamation',
      'Číslo účtu pro případné vrácení peněz': 'Numéro de compte pour un éventuel remboursement',
      'IBAN číslo účtu pro případné vrácení peněz': 'Numéro de compte IBAN pour un éventuel remboursement',
      'Prosím popište důvod reklamace zboží.': 'Veuillez décrire le motif de la réclamation du produit.',
      'Prosím zadejte číslo účtu.': 'Veuillez saisir le numéro de compte.',
      'Prosím zadejte IBAN číslo účtu.': 'Veuillez saisir le numéro de compte IBAN.',
      //end//
      //Summary.js//
      'Souhrn vratky': 'Résumé du retour',
      'Souhrn reklamace': 'Résumé de la réclamation',
      'Co provádíte:': 'Action en cours :',
      'Číslo objednávky:': 'Numéro de commande :',
      'E-mail:': 'E-mail :',
      'Produkty, které vracíte či reklamujete:': 'Produits que vous retournez ou réclamez :',
      'Důvod vrácení či reklamace:': 'Motif du retour ou de la réclamation :',
      'Rozepište důvod vrácení či reklamace:': 'Décrivez le motif du retour ou de la réclamation :',
      'IBAN číslo účtu pro případné vrácení peněz:': 'Numéro de compte IBAN pour un éventuel remboursement',
      'Odeslat': 'Envoyer',
      'Číslo účtu pro případné vrácení peněz:': 'Numéro de compte pour un éventuel remboursement :',
      'Kontaktní jméno:': 'Nom du contact :',
      'Kontaktní telefon:': 'Téléphone du contact :',
      'Kontaktní email:': 'Email du contact :',
      //end//
      //Vratka.js//
      'Důvod vratky': 'Motif du retour',
      'Důvod vrácení': 'Motif du retour',
      'Prosíme rozepište důvod vrácení': 'Veuillez préciser le motif du retour',
      'Prosím popište důvod vrácení zboží.': 'Veuillez préciser le motif du retour des articles.',
      'Bez čísla účtu': 'Sans numéro de compte',
      'Nespravný IBAN. Zadejte jej prosím ve formátu XX1111111111 a zkontrolujte na překlepy.': 'IBAN incorrect. Veuillez le saisir au format XX1111111111 et vérifiez les fautes de frappe.',
      //KontatkniUdaje
      'Jméno': 'Nom',
      'Telefon': 'Téléphone',
      'Kontaktní údaje': 'Coordonnées',
      'Prosím vyplňte kontaktní jméno.': 'Veuillez saisir le nom du contact.',
      'Prosím vyplňte kontaktní telefon.': 'Veuillez saisir le téléphone du contact.',
      'Prosím vyplňte kontaktní email.': 'Veuillez saisir l\'email du contact.',
      'Označit k reklamaci': 'Sélectionner pour réclamation',
      'Označit k vrácení': 'Sélectionner pour retour',
      'Zrušit': 'Annuler',
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: false,
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;