import React from 'react';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>

      <div >
        <div style={{ margin: "100px", fontSize: '25pt' }} >
            BOLA spol s.r.o.<br />
            Severní 276<br />
            252 25 Jinočany<br />
            Czech Republic<br />
            <br />
            {props.stitekInfo}
            <br />
        </div>
      </div>

    </div>
  );
});