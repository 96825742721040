import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function KontaktniUdaje(props) {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const checkAndMove = async () => {
    if (!props.userInput.contact_name) {
      setError('name_missing')
    } else if (!props.userInput.contact_email) {
      setError('email_missing')
    } else if (!props.userInput.contact_phone) {
      setError('phone_missing')
    } else {
      props.link('summary')
    }
    
   }


  return (
      
      <div className="row">

        <div className="col-12">

          <h2>{t('Kontaktní údaje')}</h2>

              <div className="row mb-3">
                <div className="col-11">
                  <label>{t('Jméno')}<span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="contact_name" value={props.userInput.contact_name} onChange={props.handleUserInput} placeholder="..." />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-11">
                  <label>{t('Email')}<span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="contact_email" value={props.userInput.contact_email} onChange={props.handleUserInput} placeholder={t('jmeno@domena.cz')} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-11">
                  <label>{t('Telefon')}<span className="text-danger"> *</span></label>
                    <input type="text" className="form-control" name="contact_phone" value={props.userInput.contact_phone} onChange={props.handleUserInput} placeholder="+420 xxx xxx xxx" />
                </div>
              </div>

          {
            error === 'name_missing' && !props.userInput.contact_name &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Prosím vyplňte kontaktní jméno.')}   
            </div>
          }

          {
            error === 'phone_missing' && !props.userInput.contact_phone &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Prosím vyplňte kontaktní telefon.')}   
            </div>
          }

                    {
            error === 'email_missing' && !props.userInput.contact_email &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Prosím vyplňte kontaktní email.')}   
            </div>
          }

          <div className="row mt-3">
            <div className="col-12">
              <button className="btn btn-secondary me-3" onClick={() => props.link('form')}>{t('Zpět')}</button>
              <button className="btn btn-primary" onClick={() => checkAndMove()}>{t('Pokračovat')}</button>
            </div>
          </div>

        </div>
      </div>

    )
}