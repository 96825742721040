import { useTranslation } from 'react-i18next';
import { validateIBAN, electronicFormatIBAN } from 'ibantools'

export function ReklamaceBox(props) {
  const { t } = useTranslation();
  let duvod_vraceni = {
    target: {
      name: 'duvod_vraceni',
      value: 'reklamace'
    }
  }
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{t('Reklamace')}</h5>
        <p className="card-text">{t('Reklamace zboží.')}</p>
        <button onClick={() => {props.setTypVraceni('reklamace'); props.handleUserInput(duvod_vraceni); props.link('baseInput')}} className="btn btn-primary">{t('Pokračovat')}</button>
      </div>
    </div>
  )
}

export function VratkaBox(props) {
  const { t } = useTranslation();
  let duvod_vraceni = {
    target: {
      name: 'duvod_vraceni',
      value: '14_dni'
    }
  }
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{t('Vratka')}</h5>
        <p className="card-text">{t('Vrácení zboží.')}</p>
        <button onClick={() => {props.setTypVraceni('vratka'); props.handleUserInput(duvod_vraceni); props.link('baseInput')}} className="btn btn-primary">{t('Pokračovat')}</button>
      </div>
    </div>
  )
}

export function Duvod_text(duvod_kod) {
  const { t } = useTranslation();
  let duvody = {
    '14_dni' : t('Bez důvodu do 14ti dní (pouze koncový zákazník)'),
    'poskozeno_preprava' : t('Poškozeno při přepravě'),
    'chyba_bola' : t('Chyba na straně Bola spol s.r.o.'),
    'chyba_zakaznik' : t('Chyba na straně zákazníka'),
    'jiny' : t('Jiný'),
    'reklamace': t('Reklamace'),
  }
    return (
      duvody[duvod_kod]
    )
}


export async function ValidateBankAccount(number) {
  const rawResponse = await fetch('https://vratky.bola.cz/vratky-backend/check_bank_account_number.php', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({cislo_uctu: number})
  });

  let result = false

  await rawResponse.text()
    .then((dataStr) => {
      try {
        result = JSON.parse(dataStr);
      } catch (err) {
        console.log(err)
        window.alert('Chyba. \n\n' + err + '\n\n' + dataStr)
      }
    }
  );

  return result

}

export function ValidateIban(iban) {
  return validateIBAN(electronicFormatIBAN(iban));
}

export function isInt(value) {
  return !isNaN(value) && 
         parseInt(Number(value)) == value && 
         !isNaN(parseInt(value, 10));
}