import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ValidateBankAccount, ValidateIban } from './Components'

export function Vratka(props) {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {    
    //prepsani duvodu vraceni, pokud by uzivatel se vracel z reklamace
    if (props.userInput.duvod_vraceni === 'reklamace') {
      let duvod_vraceni = {
        target: {
          name: 'duvod_vraceni',
          value: '14_dni'
        }
      }
      props.handleUserInput(duvod_vraceni)
    }
    
    let je_firma = (props.data.objednavka.fa_dic || props.data.objednavka.fa_ico);
    //prepsani duvodu vraceni, pokud by uzivatel se vracel z reklamace
    if (je_firma && props.userInput.duvod_vraceni === '14_dni') {
      let duvod_vraceni = {
        target: {
          name: 'duvod_vraceni',
          value: 'chyba_zakaznik'
        }
      }
      props.handleUserInput(duvod_vraceni)
    }
    
    

    //predvyplneni ceskyho cisla uctu
    if (props.data.objednavka.shop_country_code === 'cz' && props.data.objednavka.cislo_uctu && props.data.objednavka.kod_banky && !props.userInput.cislo_uctu_user) {
      let cislo_uctu_premier = props.data.objednavka.cislo_uctu + '/' + props.data.objednavka.kod_banky
      cislo_uctu_premier = {
        target: {
          name: 'cislo_uctu_user',
          value: cislo_uctu_premier
        }
      }
      props.handleUserInput(cislo_uctu_premier)
    }

    //predvyplneni IBAN
    if (props.data.objednavka.shop_country_code !== 'cz' && !props.userInput.iban_user) {
      let iban_premier = {
        target: {
          name: 'iban_user',
          value: props.data.objednavka.iban
        }
      }
      props.handleUserInput(iban_premier)
    }
    

  }, [])


   let needs_reason = !['14_dni','poskozeno_preprava'].includes(props.userInput.duvod_vraceni)

   const checkAndMove = async () => {
    if (needs_reason && !props.userInput.duvod_vraceni_detail) {
      setError('duvod_missing')
    } else if (!props.userInput.cislo_uctu_user && props.data.objednavka.shop_country_code === 'cz' && !props.userInput.no_account_number) {
      setError('ucet_missing')
    } else if (!props.userInput.iban_user && props.data.objednavka.shop_country_code !== 'cz' && !props.userInput.no_account_number) {
      setError('iban_missing')
    } else if (props.data.objednavka.shop_country_code !== 'cz' && !props.userInput.no_account_number) {
      let iban_valid = ValidateIban(props.userInput.iban_user).valid
      if (!iban_valid) {setError('wrong_iban')} else {props.link('contact')}
    } else if (props.data.objednavka.shop_country_code === 'cz' && !props.userInput.no_account_number) {
      let bank_account_valid = await ValidateBankAccount(props.userInput.cislo_uctu_user)
      if (!bank_account_valid) {setError('wrong_account_number')} else {props.link('contact')}
    } else {
      props.link('contact')
    }
    
   }
   
  let je_firma = (props.data.objednavka.fa_dic || props.data.objednavka.fa_ico);

  return (
      
      <div className="row">

        <div className="col-12">

          <h2>{t('Důvod vratky')}</h2>

          <div className="row mb-3">
            <div className="col-11">
              <label>{t('Důvod vrácení')}<span className="text-danger">*</span></label>
                <select className="form-select" value={props.userInput.duvod_vraceni} name="duvod_vraceni" onChange={props.handleUserInput} >
                  {!je_firma &&
                    <option value="14_dni">{t('Bez důvodu do 14ti dní (pouze koncový zákazník)')}</option>
                  }
                  <option value="poskozeno_preprava">{t('Poškozeno při přepravě')}</option>
                  <option value="chyba_bola">{t('Chyba na straně Bola spol s.r.o.')}</option>
                  <option value="chyba_zakaznik">{t('Chyba na straně zákazníka')}</option>
                  <option value="jiny">{t('Jiný')}</option>
                </select>
            </div>
          </div>


          <div className="row mb-3">
            <div className="col-11">
              <label>{t('Prosíme rozepište důvod vrácení')} { !['14_dni','poskozeno_preprava'].includes(props.userInput.duvod_vraceni) && <span className="text-danger">*</span>}</label>
              <textarea maxLength="500" className="form-control" rows="2" placeholder="..." name="duvod_vraceni_detail" value={props.userInput.duvod_vraceni_detail} onChange={props.handleUserInput}></textarea>
            </div>
          </div>

          {props.data.objednavka.shop_country_code === 'cz' &&
            <>
              <div className="row mb-3">
                <div className="col-11">
                  <label>Číslo účtu pro případné vrácení peněz <span className="text-danger">*</span></label>
                    <input disabled={props.userInput.no_account_number} type="text" className="form-control" name="cislo_uctu_user" value={props.userInput.cislo_uctu_user} onChange={props.handleUserInput} placeholder="xxxxxx/xxxx" />
                </div>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" name="no_account_number" checked={props.userInput.no_account_number} onChange={props.handleUserInput} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t('Bez čísla účtu')}
                </label>
              </div>
            </> 
          }

          {props.data.objednavka.shop_country_code !== 'cz' &&
            <>
              <div className="row mb-3">
                <div className="col-11">
                  <label>{t('IBAN číslo účtu pro případné vrácení peněz')} <span className="text-danger">*</span></label>
                    <input disabled={props.userInput.no_account_number} type="text" className="form-control" name="iban_user" value={props.userInput.iban_user} onChange={props.handleUserInput} placeholder="XX000000000" />
                </div>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" name="no_account_number" checked={props.userInput.no_account_number} onChange={props.handleUserInput} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t('Bez čísla účtu')}
                </label>
              </div>
            </>
          }

          {
            error === 'duvod_missing' && !props.userInput.duvod_vraceni_detail &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Prosím popište důvod vrácení zboží.')}   
            </div>
          }
          {
            error === 'ucet_missing' && !props.userInput.cislo_uctu_user &&
            <div className="alert alert-danger mt-3" role="alert">
               {t('Prosím zadejte číslo účtu.')}  
            </div>
          }

          {
            error === 'iban_missing' && !props.userInput.iban_user &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Prosím zadejte IBAN číslo účtu.')}   
            </div>
          }

          {
            error === 'wrong_account_number' && !props.userInput.no_account_number &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Nespravné číslo účtu. Zadejte jej prosím ve formátu xx-xxxxxxxxx/xxxx a zkontrolujte na překlepy.')}   
            </div>
          }

          {
            error === 'wrong_iban' && !props.userInput.no_account_number &&
            <div className="alert alert-danger mt-3" role="alert">
              {t('Nespravný IBAN. Zadejte jej prosím ve formátu XX1111111111 a zkontrolujte na překlepy.')}   
            </div>
          }

          <div className="row mt-3">
            <div className="col-12">
              <button className="btn btn-secondary me-3" onClick={() => props.link('product_selector')}>{t('Zpět')}</button>
              <button className="btn btn-primary" onClick={() => checkAndMove()}>{t('Pokračovat')}</button>
            </div>
          </div>

        </div>
      </div>

    )
}